<template>
  <ul
    class="mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
    role="tablist"
  >
    <li
      role="presentation"
      v-if="UserPermissions?.AppInfo?.includes('app_info')"
    >
      <router-link
        to="/app/info"
        class="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-gray-500 hover:isolate hover:border-transparent hover:bg-gray-100 focus:isolate focus:border-transparent"
        role="tab"
        >Info</router-link
      >
    </li>
    <li
      role="presentation"
      v-if="UserPermissions?.AppInfo?.includes('app_url')"
    >
      <router-link
        to="/app/url"
        class="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-gray-500 hover:isolate hover:border-transparent hover:bg-gray-100 focus:isolate focus:border-transparent"
        role="tab"
        >Url</router-link
      >
    </li>
  </ul>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
</template>
<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
const store = useStore();
const router = useRouter();
const UserPermissions = computed(
  () => store?.getters['getUserPermissionsData']
);
const redirect = () => {
  let path;
  if (UserPermissions.value?.AppInfo?.includes('app_url')) {
    path = '/app/url';
  } else {
    path = '/app/info';
  }
  return router.push(path);
};
onMounted(() => {
  redirect();
});
</script>
<style scoped>
.router-link-active,
.router-link-exact-active {
  @apply border-blue-800 text-blue-800;
}
</style>
